import React, { useState, useEffect, useRef } from 'react'
import bg3 from '../../assets/images/bg3.jpg'
import email from '../../assets/images/emails.jpg'
import commerce from '../../assets/images/commerce.jpg'
import pwa from '../../assets/images/pwa.jpg'
import bookings from '../../assets/images/bookings.jpg'
import webpage from '../../assets/images/webpage.jpg'
import ux from '../../assets/images/ux.jpg'
import './Services.css'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';


import ServiceBox from './ServiceBox/ServiceBox'

function Services() {

    const [selBox, setSelBox] = useState('webpages')
    const [boxDesc, setBoxDesc] = useState('')
    const [header, setHeader] = useState('Web Development')
    const [scale, setScale] = useState('')

    const scrollRef = useRef()
    const mobRef = useRef()
    const visRef = useRef()

    function useOnScreen(ref) {

        const [isIntersecting, setIntersecting] = useState(false)
      
        const observer = new IntersectionObserver(
          ([entry]) => setIntersecting(entry.isIntersecting)
        )
      
        useEffect(() => {
          observer.observe(ref.current)
          // Remove the observer as soon as the component is unmounted
          return () => { observer.disconnect() }
        }, [])
      
        return isIntersecting
      }

      const isVisible = useOnScreen(visRef)
      const mobVisible = useOnScreen(mobRef)

    // console.log(isBrowser, isMobile)

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])


  return (
    <div className='services'
    style={{backgroundImage: 'url("' + bg3 +'")'}}

    >

        <div className="page-header tracking-in-expand-fwd">
            <h1>What we do</h1>
        </div>
        <div className="page-sub tracking-in-expaand-fwd">
            <p>Any of our services are 100% tailor made to suit the client, which means we unfortunately cannot offer a set price list - please get in touch so we can work out what works for both of us!</p>
        </div>
        


    <div 
    className="home-services scale-in-center fade-in">
        <div className="hs-row">
            <div className="hs-text">
                <h2>Select an item to find out more</h2>
                <p>{boxDesc}</p>
                {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum, odio quasi quia laudantium, recusandae ducimus id aut sint ipsam dolore totam.</p> */}
            </div>
            <ServiceBox handleClick={() => {setSelBox('webpages'); setHeader('Web Development');
            if (!isVisible) {
                if (isMobile) {
                    return mobRef.current.scrollIntoView({behavior: 'smooth'})
                }
                scrollRef.current.scrollIntoView({behavior: 'smooth'})
            }
        }} header={'Web Development'} active={selBox === 'webpages'} key='web-pages' />

        <ServiceBox handleClick={() =>{ setSelBox('email');  setHeader('email marketing');
                if (!isVisible) {
                    if (isMobile) {
                        return visRef.current.scrollIntoView({behavior: 'smooth'})
                    }
                    scrollRef.current.scrollIntoView({behavior: 'smooth'})
                }}} header={'Email Marketing'} active={selBox === 'email'} key='email' />
        </div>
        <div className="hs-row">
        <ServiceBox handleClick={() => {setSelBox('webapps'); setHeader('Online Commerce');
                if (!isVisible) {
                    if (isMobile) {
                        return visRef.current.scrollIntoView({behavior: 'smooth'})
                    }
                    scrollRef.current.scrollIntoView({behavior: 'smooth'})
                } }} header={'Online Commerce'} active={selBox === 'webapps'} key='webapps' />

        <ServiceBox handleClick={() => {setSelBox('pwas'); setHeader('Mobile Web Apps');
                if (!isVisible) {
                    if (isMobile) {
                        return visRef.current.scrollIntoView({behavior: 'smooth'})
                    }
                    scrollRef.current.scrollIntoView({behavior: 'smooth'})
                }}} header={'Mobile Applications'} active={selBox === 'pwas'} key='pwas' />
        <ServiceBox handleClick={() => {setSelBox('booking'); setHeader('Booking Systems');
                if (!isVisible) {
                    if (isMobile) {
                        return visRef.current.scrollIntoView({behavior: 'smooth'})
                    }
                    scrollRef.current.scrollIntoView({behavior: 'smooth'})
                }}} header={'Booking Systems'} active={selBox === 'booking'} key='booking' />
        <ServiceBox handleClick={() => {setSelBox('uxui'); setHeader('Interface Design');
                if (!isVisible) {
                    if (isMobile) {
                        return visRef.current.scrollIntoView({behavior: 'smooth'})
                    }
                    scrollRef.current.scrollIntoView({behavior: 'smooth'})
                }}} header={'Interface Design'} active={selBox === 'uxui'} key='uxui' />
        </div>
        
       
    </div>
    <div className="" ref={mobRef}></div>

    <div className={`services-content ${scale}`}>
        <div                     
 className="sc-head">
            <h2>{header}</h2>
        </div>
<div className=""         ref={visRef}
></div>
        {selBox === 'webpages' && <>
            <div className="sc-container scale-in-center fade-in">
                <div className="scc-left">
                    <img 
                    src={webpage}
                    />
                </div>
                <div className="scc-right">
                    <h3>Display all your business has to offer</h3>
                    <p>First impressions are incredibly important - a reported 88% of online consumers are less likely to return to a site after a bad experience, burning a prospective relationship and creating the potential for bad word of mouth. <br/><br/>
                        The advent of web applications has allowed for simple web pages to become far more powerful, creating many new potential ways to interact with clients. Get in touch to find out how we can create a solution tailor made for your needs.
                    </p>
                </div>
            </div>
        </>}

        {selBox === 'email' && <>
            <div className="sc-container scale-in-center fade-in">
                <div className="scc-left">
                    <img 
                    src={email}
                    />
                </div>
                <div className="scc-right">
                    <h3>Communicate with your customers and clients</h3>
                    <p>Despite being one of the oldest techniques around, emails remain are viable and reliable method of keeping your clients up to date with your latest developments <br/><br/>
                    Informative and well designed emails can hugely boost interaction between you and your customers, and drive traffic back to your website. 
                    </p>
                </div>
            </div>
        </>}

        {selBox === 'webapps' && <>
            <div className="sc-container scale-in-center fade-in">
                <div className="scc-left">
                    <img 
                    src={commerce}
                    />
                </div>
                <div className="scc-right">
                    <h3>Communicate with your customers and clients</h3>
                    <p>Despite being one of the oldest techniques around, emails remain are viable and reliable method of keeping your clients up to date with your latest developments <br/><br/>
                    Informative and well designed emails can hugely boost interaction between you and your customers, and drive traffic back to your website. 
                    </p>
                </div>
            </div>
        </>}

        {selBox === 'pwas' && <>
            <div className="sc-container scale-in-center fade-in">
                <div className="scc-left">
                    <img 
                    src={pwa}
                    />
                </div>
                <div className="scc-right">
                    <h3>The modern way to distribute apps</h3>
                    <p>Releasing mobile applications through the respective app stores can become quite laborious, having to wait for changes to be both approved and implemented. Enter Mobile Web Apps. <br/><br/>
                    New advances in technologies allow MWAs to be run through the phone's browser, are installed in a matter of seconds, and for the most part behave just like regular native apps. As if that was not enough, changes are reflected on the user's side as soon as they are deployed - what's not to love about instant updates
                    </p>
                </div>
            </div>
        </>}

        {selBox === 'booking' && <>
            <div className="sc-container scale-in-center fade-in">
                <div className="scc-left">
                    <img 
                    src={bookings}
                    />
                </div>
                <div className="scc-right">
                    <h3>Streamline your processes</h3>
                    <p>One of the biggest challenges, especially for business getting started, is the amount of different bases that need to be covered, not least when it comes to client interaction.<br/><br/>
                    A simple booking system gives your clients the chance to make bookings at their own leisure, giving them all the information they need at an instant. All this allows you to focus on your every day requirements, as well as watch the bookings stream in.
                    </p>
                </div>
            </div>
        </>}

        {selBox === 'uxui' && <>
            <div className="sc-container scale-in-center fade-in">
                <div className="scc-left">
                    <img 
                    src={ux}
                    />
                </div>
                <div className="scc-right">
                    <h3>Stand out from the rest</h3>
                    <p>According to recent data, first impressions of a web site are 94% design related - if the site does not meet a certain visual standard, the content is barely even noticed.<br/><br/>
                    Our experience in web design, as well as client interaction allows us to optimise the time a consumer spends on your site, ensuring the design of the page matches the purpose 
                    </p>
                </div>
            </div>
        </>}

        <div ref={scrollRef}></div>

    </div>


    </div>
  )
}

export default Services