import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import './MenuBar.css'
import MenuIcon from '@mui/icons-material/Menu';
import { Drawer } from '@mui/material';

import logo from '../../assets/images/ysfooter.svg'

function MenuBar() {

    const [showDrawer, setShowDrawer] = useState(false)

    const navigate = useNavigate()

  return (
    <div className='menu-bar'>

        <Drawer onClick={() => setShowDrawer(false)} anchor='top' open={showDrawer} onClose={() => setShowDrawer(false)}>
            <div className="drawer-menu">
            <NavLink 
                to='/'
                className={({isActive}) => isActive ? 'nav-link nav-link-active' : 'nav-link'}
            >Home
            </NavLink>
            <NavLink 
                to='/services'
                className={({isActive}) => isActive ? 'nav-link nav-link-active' : 'nav-link'}
            >Services
            </NavLink>
            <NavLink 
                to='/work'
                className={({isActive}) => isActive ? 'nav-link nav-link-active' : 'nav-link'}
            >Our Work
            </NavLink>
            <NavLink 
                style={{marginRight: '0px'}}
                to='/contact'
                className={({isActive}) => isActive ? 'nav-link nav-link-active' : 'nav-link'}
            >Contact
            </NavLink>
            </div>
        </Drawer>

        <div onClick={() => navigate('/')} className="mb-left">
            <img src={logo} />
        </div>
        <div className="mb-middle">

        </div>
        <div className="mb-right">
            <NavLink 
                to='/'
                className={({isActive}) => isActive ? 'nav-link nav-link-active' : 'nav-link'}
            >Home
            </NavLink>
            <NavLink 
                to='/services'
                className={({isActive}) => isActive ? 'nav-link nav-link-active' : 'nav-link'}
            >Services
            </NavLink>
            <NavLink 
                to='/work'
                className={({isActive}) => isActive ? 'nav-link nav-link-active' : 'nav-link'}
            >Our Work
            </NavLink>
            <NavLink 
                style={{marginRight: '0px'}}
                to='/contact'
                className={({isActive}) => isActive ? 'nav-link nav-link-active' : 'nav-link'}
            >Contact
            </NavLink>
        </div>
        <div onClick={() => setShowDrawer(current => !current)} className="mb-burger">
            <MenuIcon style={{color: 'white'}} />
        </div>
    </div>
  )
}

export default MenuBar