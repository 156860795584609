import React from 'react'
import './App.css';
import './Animations.css'
import './ButtonAnimations.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

// Pages
import Home from './pages/Home/Home'
import Services from './pages/Services/Services';
import Contact from './pages/Contact/Contact';
import Work from './pages/Work/Work';

// Components
import MenuBar from './components/MenuBar/MenuBar';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <div className="App">
      <Router>
      <MenuBar />

        <Routes>
          <Route path='/' element={<Home />}/>
          <Route path='/services' element={<Services />}/>
          <Route path='/contact' element={<Contact />} />
          <Route path='/work' element={<Work />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
