import React from 'react'
// import logo from '../../assets/images/yenzasideblack.svg'
import './Footer.css'
import logo from '../../assets/images/ysfooter.svg'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
function Footer() {
  return (
    <div className='footer'>
        <div className="footer-left">
        <img src={logo} />

        </div>
        <div className="footer-middle">
          <p>Ⓒ Yenza Tech, 2022. All Rights Reserved</p>
        </div>
        <div className="footer-right">
            {/* <h3>Contact Us</h3> */}
            <div className="fr-item" onClick={() => window.open("tel:+27768359609")}>
                    <PhoneIcon className='fr-svg' />
                    <p>(+27) 76 835 9609</p>
            </div>
            <div className="fr-item">
                <EmailIcon onClick={() => window.open("mailto:info@yenzatech.co.za")} className='fr-svg' />
                    <p>info@yenzatech.co.za</p>
            </div>
            <div className="fr-item">
                <LocationOnIcon onClick={() => window.open("mailto:info@yenzatech.co.za")} className='fr-svg' />
                    <p>Cape Town, South Africa</p>
            </div>
        </div>
    </div>
  )
}

export default Footer