import React, { useState, useEffect, useRef } from 'react'
import './Contact.css'

import bg3 from '../../assets/images/bg3.jpg'

import { Checkbox } from '@mui/material';

import { db } from '../../firebase';


function Contact() {

  const [check, setCheck] = useState(false)
  const [fin, setFin] = useState(false)
  const [loading, setLoading] = useState(false)
  const [update, setUpdate] = useState(0)
  const [error, setError] = useState('')

  const nameRef = useRef();
  const contactRef = useRef();
  const subjectRef = useRef()
  const textRef = useRef()

  
 function validate() {

    setError('')

    const name = nameRef.current.value;
    const contact = contactRef.current.value;
    const text = textRef.current.value;

    if (name === '') {
      setError('Please let us know what your name is')
      return false
    }
    if (contact === '') {
      setError('Please let us know how to contact you')
      return false
    }
    if (text === '') {
      setError('Please don\'t forget to enter a message')
      return false
    }

    if (!check) {
      setError('Please don\'t forget to check the box so we can contact you!')
      return false
    }
  }

  async function saveQuery() {

    const name = nameRef.current.value;
    const contact = contactRef.current.value;
    const text = textRef.current.value;
    
    if (validate() === false) {
      return
    }

    setLoading(true)

    try {
      await db.collection('queries').add({
        date: new Date(),
        name,
        contact,
        text,
        check,

      });
      setLoading(false)
      setFin(true)

    } catch (err) {
      console.log(err)
      return setError('Sorry, we experienced some issues there - please try submit your message again')
    }
  }

  return (
    <div className='contact'
    style={{backgroundImage: 'url("' + bg3 +'")'}}
    >
      <div className="page-header tracking-in-expand-fwd">
        <h1>Contact Us</h1>
      </div>

      {!fin ? <>

    <div className="contact-container fade-in scale-in-center">
      <div className="contact-left">
        <div className="cl-row">
          <input type={'text'} placeholder='Name' ref={nameRef} />
          <input type={'text'} placeholder='Email / Phone' ref={contactRef} />
        </div>
        <input type="text" ref={subjectRef} placeholder='Subject' />
        <textarea placeholder='Enter your message here' ref={textRef} rows="10" />
        <div className="check-row">
          <Checkbox 
          value={check}
          onChange={() => setCheck(current => !current)}
          />
          <p 
          // onClick={() =>{ setCheck(current => !current); setUpdate(current => current + 1)}} 
          style={{color: check ? 'white' : 'grey'}}>I hereby allow Yenza to contact me with regards to this query</p>
        </div>

      </div>

      {/* <div className="contact-right">
        <h1>Info n shit</h1>
      </div> */}

    </div>

    {error !== '' && <div className="error-message-row">
        <p>{error}</p>
    </div>}

    <div className="button-row hb-buttons">
        <button onClick={() => saveQuery()} className='hb-button pulse'>
                    Submit
                </button>
        </div>
        </> :
        <>
        <div className="contact-finished scale-in-center">
          <h1>Success!</h1>
          <p>Thank you for submitting your enquiry, we will be in touch as soon as possible</p>
        </div>
        </>}

    </div>
  )
}

export default Contact