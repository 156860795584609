import React, { useState, useEffect } from 'react'
import './ServiceBox.css'
import {CgWebsite} from 'react-icons/cg'
import {FiSmartphone} from 'react-icons/fi'
import AllInboxIcon from '@mui/icons-material/AllInbox';
import WebhookIcon from '@mui/icons-material/Webhook';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { useNavigate } from 'react-router-dom';

function ServiceBox({ header, logo, active, handleClick}) {

    const [img, setImg] = useState('')

    const navigate = useNavigate()

    useEffect(() => {
        if (header === 'Web Development') {
            return setImg(<WebhookIcon className='sb-svg' />)
        }
        if (header === 'Online Commerce') {
            return setImg(<AddShoppingCartIcon className='sb-svg' />)
        }
        if (header === 'Mobile Applications') {
            return setImg(<FiSmartphone className='sb-svg' />)
        } 
        if (header === 'Email Marketing') {
            return setImg(<AllInboxIcon className='sb-svg' />)
        }
        if (header === 'Booking Systems') {
            return setImg(<EventAvailableIcon className='sb-svg' />)
        }
        if (header === 'Interface Design') {
            return setImg(<DashboardIcon className='sb-svg' />)
        }

        // else {
            setImg(<WebhookIcon className='sb-svg' />)
        // }
    }, [])

    useEffect(() => {
        // console.log(active)
    }, [active])

  return (
    <div onClick={handleClick} className={active ? 'service-box service-box-active' : 'service-box'}>
        <div className="sb-logo">
            {img}
            {/* <img alt={header} src={img} /> */}
        </div>
        <div className="sb-text">
            <h2>{header}</h2>
        </div>
        <div onClick={() => navigate('/services')} className="sb-arrow">
            <ArrowForwardIosIcon />
        </div>
    </div>
  )
}

export default ServiceBox