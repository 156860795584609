import React, { useEffect } from 'react'
import './Work.css'
import bg3 from '../../assets/images/bg3.jpg'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import screen3 from '../../assets/images/forte.png'
import anoud from '../../assets/images/anoud.png'
import acc from '../../assets/images/acc.png'
import cu from '../../assets/images/cu.png'
import ProjectBox from './ProjectBox'
import uga from '../../assets/images/ugaapp.png'
import shell from '../../assets/images/scre.png'
import vhembe from '../../assets/images/vhembe_logo.png'
function Work() {

    
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

  return (
    <div className='work'
    style={{backgroundImage: 'url("' + bg3 +'")'}}

    >
        <div className="page-header tracking-in-expand-fwd">
            <h1>Our Projects</h1>
        </div>

        <div className="page-sub tracking-in-expaand-fwd">
            <p>A look at some of the work we have done for previous clients</p>
        </div>

        {isMobile && <div className='mobile-msg msg-center'>
                <p>Tap on an image to view more</p>
            </div>}

<div className="home-work scale-in-center">
        <div className="hw-links hwl-top fade-in ">
        <ProjectBox 
            title={'The UGA'}
            type={'Web & Mobile'}
            link='https://www.theugagolf.com/'
            key={'UGA'}
            image={uga}
            // image={'https://static.wixstatic.com/media/0c398c_3e1a7d311a57485394f313ee285dc8fa~mv2.png/v1/crop/x_46,y_0,w_989,h_1920/fill/w_392,h_758,al_c,usm_0.66_1.00_0.01,enc_auto/Find%20a%20specialist.png'}
            />
                    <ProjectBox 
            title={'Vhembe App'}
            type={'Mobile'}
            link='https://www.vhembeapp.com/'
            key={'Vhembe'}
        image={vhembe}
            // image={'https://static.wixstatic.com/media/0c398c_3e1a7d311a57485394f313ee285dc8fa~mv2.png/v1/crop/x_46,y_0,w_989,h_1920/fill/w_392,h_758,al_c,usm_0.66_1.00_0.01,enc_auto/Find%20a%20specialist.png'}
            />
                    <ProjectBox 
            title={'Shells Stories'}
            type={'Web'}
            link='https://shellsstories.co.za/'
            key={'Shell'}
            image={shell}
            // image={'https://static.wixstatic.com/media/0c398c_3e1a7d311a57485394f313ee285dc8fa~mv2.png/v1/crop/x_46,y_0,w_989,h_1920/fill/w_392,h_758,al_c,usm_0.66_1.00_0.01,enc_auto/Find%20a%20specialist.png'}
            />
            <ProjectBox 
            title={'Forte Elite'}
            type={'Web Platform'}
            link='https://www.forte-app.com/'
            key={'Forte'}
            image={screen3}
            // image={'https://static.wixstatic.com/media/0c398c_3e1a7d311a57485394f313ee285dc8fa~mv2.png/v1/crop/x_46,y_0,w_989,h_1920/fill/w_392,h_758,al_c,usm_0.66_1.00_0.01,enc_auto/Find%20a%20specialist.png'}
            />
            <ProjectBox 
            title={'Anoud Manor'}
            type={'Web Development'}
            link='https://www.anoudmanor.com/#/'
            key={'Anoud Manor'}
            // image={anoud}
            image={'https://cf.bstatic.com/xdata/images/hotel/max1024x768/160044501.jpg?k=e36c79bbe0f33863f566aabf85cddcbbb3bccd5906de8152e7df14fe03105ecf&o=&hp=1'}
            />
            <ProjectBox 
            title={'Forte App'}
            type={'Mobile'}
            link='https://www.forte-app.com/'
            key={'Forte'}
            image={'https://static.wixstatic.com/media/0c398c_3e1a7d311a57485394f313ee285dc8fa~mv2.png/v1/crop/x_46,y_0,w_989,h_1920/fill/w_392,h_758,al_c,usm_0.66_1.00_0.01,enc_auto/Find%20a%20specialist.png'}
            />


        {/* <div className="hw-links"> */}
            <ProjectBox 
            title={'Connect Up'}
            type={'Web & Commerce'}
            image={cu}
            key={'ConnectUP'}
            link='https://connect-up-c1109.web.app/#/'
            />
             <ProjectBox 
            title={'All Cape'}
            type={'Web Development'}
            link='https://all-cape-site.web.app/'
            key={'All Cape'}
            image={acc}

            // image='https://firebasestorage.googleapis.com/v0/b/all-cape-site.appspot.com/o/site%2Fimage.png?alt=media&token=ca64a076-6f5d-46a4-bbb4-228d5dddc809'
            // image={'https://static.wixstatic.com/media/0c398c_3e1a7d311a57485394f313ee285dc8fa~mv2.png/v1/crop/x_46,y_0,w_989,h_1920/fill/w_392,h_758,al_c,usm_0.66_1.00_0.01,enc_auto/Find%20a%20specialist.png'}
            />
            <ProjectBox 
            title={'Smash It!'}
            type={'Mobile App & Booking System'}
            image='https://sxa.co.za/images/header-iphone.png'
            key={'Smashed It App'}
            link='http://smashedit.co.za/app/index.html'
            />
        </div>
        </div>

    </div>
  )
}

export default Work