
import 'firebase/compat/firestore'
import firebase from 'firebase/compat/app'


const firebaseConfig = {
    apiKey: "AIzaSyC-Z428o9VecCAlfPD122wTIKXRrB8ohX4",
    authDomain: "yenza-site.firebaseapp.com",
    projectId: "yenza-site",
    storageBucket: "yenza-site.appspot.com",
    messagingSenderId: "117056382728",
    appId: "1:117056382728:web:a0da849fe86b4117492ebd",
    measurementId: "G-6M1ETYZC5G"
  };

//   const app = initializeApp(firebaseConfig);
const app = firebase.initializeApp(firebaseConfig)

  export const admin = firebase;
  export const db = app.firestore();
//   export const auth = app.auth();
//   export const storage = app.storage();
//   export const database = app.database()
  export default app  