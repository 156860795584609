import React from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './ProjectBox.css'

function ProjectBox({ image, title, type, link }) {
  return (
    <div style={{backgroundImage: 'url("' + image + '")'}} className='project-box '>
        <div className="pb-text">
            <p>{type}</p>
            <h3>{title}</h3>
            <div onClick={() => window.open(link, 'blank').focus()} className="pb-arrow">
                <ArrowForwardIosIcon className='pb-svg' />
            </div>
        </div>
    </div>
  )
}

export default ProjectBox