import React, { useState, useEffect, useRef } from 'react'
import './Home.css'
import { useNavigate } from 'react-router-dom'

import bg from '../../assets/images/bg1.jpg'
import bg2 from '../../assets/images/bg2.jpg'
import bg3 from '../../assets/images/bg3.jpg'
import logo from '../../assets/images/2.png'
import screen3 from '../../assets/images/forte.png'
import uga from '../../assets/images/ugaapp.png'
import shell from '../../assets/images/scre.png'
import vhembe from '../../assets/images/vhembe_logo.png'

import ServiceBox from '../Services/ServiceBox/ServiceBox'
import ProjectBox from '../Work/ProjectBox'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';


function Home() {

    function useOnScreen(ref) {

        const [isIntersecting, setIntersecting] = useState(false)
      
        const observer = new IntersectionObserver(
          ([entry]) => setIntersecting(entry.isIntersecting)
        )
      
        useEffect(() => {
          observer.observe(ref.current)
          // Remove the observer as soon as the component is unmounted
          return () => { observer.disconnect() }
        }, [])
      
        return isIntersecting
      }



    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const [selBox, setSelBox] = useState('webpages')
    const [boxDesc, setBoxDesc] = useState('')
    const [picClass, setPicClass] = useState('')
    const [workAni, setWorkAni] = useState('')

    const scrollRef = useRef()

    const workRef = useRef()
    const workVisibile = useOnScreen(workRef)

    useEffect(() => {
        // alert(workVisibile)
        if (workVisibile) {
            setWorkAni('scale-in-center')

        } else {
            // setWorkAni('')
        }
    }, [workVisibile])

    const navigate = useNavigate()

    // console.log(isMobile);

    useEffect(() => {

        if (selBox === 'webpages') {
            setBoxDesc('Showcase all that you have to offer to the world with either a simple one-pager, or a beautiful multi page website - you can even add a blog if you like!')
        }
        if (selBox === 'email') {
            setBoxDesc('Want to keep in touch with your clients? Emails remain one of the most reliable and versatile communication techniques to interact with existing clients and attract new ones.')
        }
        if (selBox === 'webapps') {
            setBoxDesc('Display and sell your products or services online to millions of potential customers. The e-commerce market is only growing, totalling $870 billion in 2021 - 14% up on 2020.')
        }
        if (selBox === 'booking') {
            setBoxDesc('A simple online booking system can take your operations to the next level. We also build custom management tools, allowing you to oversee and manage your business in a way most suited to you.')
        }
        if (selBox === 'uxui') {
            setBoxDesc('We don\'t just build websites - we can design them too! A well structured user interface is key to users spending more time on your site, as well as building up that trust in the relationship')
        }
        if (selBox === 'pwas') {
            setBoxDesc('Use the latest in mobile technology to get your app installed directly on your client\'s phone through the browser - no need to get any app stores involved.')
        }


    }, [selBox])

  return (
    <div className='home'
    style={{backgroundImage: 'url("' + bg3 +'")'}}
    >
    
    <div  
    // style={{backgroundImage: 'url("' + bg2 +'")'}} 
    className="home-banner ">
        <div className={`hb-image ${picClass}`}>
            <img onLoad={() => setPicClass('jello-horizontal')} src={logo} />
        </div>
        <div className="hb-right scale-in-hor-leftt fade-in ">
            <div className="hb-text">
                <h1 className='hb-h1'>Yenza Tech</h1>
                <p>Simple, fast and effective web solutions to improve client interaction</p>
            </div>
            <div className="hb-buttons">
                <button
                 onClick={() => navigate('/services')} 
                className='hb-button pulse'>
                    What we do
                </button>
                <button 
                onClick={() => navigate('/contact')}
                className='hb-button pulse ml-20'>
                    Contact Us
                </button>
                </div>            
        </div>

    </div>

    <div 
    // style={{backgroundImage: 'url("' + bg2 +'")'}} 
    className="home-services fade-in scale-in-center">
        <div className="hs-row">
            <div ref={scrollRef} className="hs-text">
                <h2>We shape the perfect solutions</h2>
                <p >{boxDesc}</p>
                {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum, odio quasi quia laudantium, recusandae ducimus id aut sint ipsam dolore totam.</p> */}
            </div>
            <ServiceBox handleClick={() => {setSelBox('webpages')
        if (isMobile) {
            scrollRef.current.scrollIntoView({behavior: 'smooth'})
        }
        }} header={'Web Development'} active={selBox === 'webpages'} key='web-pages' />
        <ServiceBox handleClick={() => {setSelBox('webapps')
    if (isMobile) {
        scrollRef.current.scrollIntoView({behavior: 'smooth'})
    }
    }} header={'Online Commerce'} active={selBox === 'webapps'} key='webapps' />
        </div>
        <div className="hs-row">
        <ServiceBox handleClick={() => {setSelBox('email')
    if (isMobile) {
        scrollRef.current.scrollIntoView({behavior: 'smooth'})
    }
    }} header={'Email Marketing'} active={selBox === 'email'} key='email' />
        <ServiceBox handleClick={() => {setSelBox('pwas')
    if (isMobile) {
        scrollRef.current.scrollIntoView({behavior: 'smooth'})
    }
    }} header={'Mobile Applications'} active={selBox === 'pwas'} key='pwas' />
        <ServiceBox handleClick={() => {setSelBox('booking')
    if (isMobile) {
        scrollRef.current.scrollIntoView({behavior: 'smooth'})
    }
    }} header={'Booking Systems'} active={selBox === 'booking'} key='booking' />
        <ServiceBox handleClick={() => {setSelBox('uxui')
    if (isMobile) {
        scrollRef.current.scrollIntoView({behavior: 'smooth'})
    }
    }} header={'Interface Design'} active={selBox === 'uxui'} key='uxui' />
        </div>
        
       
    </div>

    <div className="home-work">
        <div className="hw-header">
            <h3>Our Recent Projects</h3>
        </div>

        <div className="hw-buttons">
            <button onClick={() => navigate('/work')} className='hb-button'>See More</button>
        </div>

        {isMobile && <div className='mobile-msg'>
                <p>Tap on an image to view more</p>
            </div>}

        <div style={{opacity: workVisibile ? '1' : '0'}}  ref={workRef}  className={` ${workAni} hw-links`}>


        <ProjectBox 
            title={'The UGA'}
            type={'Web & Mobile'}
            link='https://www.theugagolf.com/'
            key={'UGA'}
            image={uga}
            // image={'https://static.wixstatic.com/media/0c398c_3e1a7d311a57485394f313ee285dc8fa~mv2.png/v1/crop/x_46,y_0,w_989,h_1920/fill/w_392,h_758,al_c,usm_0.66_1.00_0.01,enc_auto/Find%20a%20specialist.png'}
            />
                    <ProjectBox 
            title={'Vhembe App'}
            type={'Mobile'}
            link='https://www.vhembeapp.com/'
            key={'Vhembe'}
        image={vhembe}
            // image={'https://static.wixstatic.com/media/0c398c_3e1a7d311a57485394f313ee285dc8fa~mv2.png/v1/crop/x_46,y_0,w_989,h_1920/fill/w_392,h_758,al_c,usm_0.66_1.00_0.01,enc_auto/Find%20a%20specialist.png'}
            />
                    <ProjectBox 
            title={'Shells Stories'}
            type={'Web'}
            link='https://shellsstories.co.za/'
            key={'Shell'}
            image={shell}
            // image={'https://static.wixstatic.com/media/0c398c_3e1a7d311a57485394f313ee285dc8fa~mv2.png/v1/crop/x_46,y_0,w_989,h_1920/fill/w_392,h_758,al_c,usm_0.66_1.00_0.01,enc_auto/Find%20a%20specialist.png'}
            />
        <ProjectBox 
            title={'Forte Elite'}
            type={'Web & Mobile'}
            link='https://elite.forte-app.com/'
            key={'Forte'}
            image={screen3}
            // image={'https://static.wixstatic.com/media/0c398c_3e1a7d311a57485394f313ee285dc8fa~mv2.png/v1/crop/x_46,y_0,w_989,h_1920/fill/w_392,h_758,al_c,usm_0.66_1.00_0.01,enc_auto/Find%20a%20specialist.png'}
            />
            <ProjectBox 
            title={'Anoud Manor'}
            type={'Web Development'}
            link='https://www.anoudmanor.com/#/'
            key={'Anoud Manor'}
            image={'https://cf.bstatic.com/xdata/images/hotel/max1024x768/160044501.jpg?k=e36c79bbe0f33863f566aabf85cddcbbb3bccd5906de8152e7df14fe03105ecf&o=&hp=1'}
            />
            <ProjectBox 
            title={'Forte App'}
            type={'Mobile'}
            link='https://www.forte-app.com/'
            key={'Forte'}
            image={'https://static.wixstatic.com/media/0c398c_3e1a7d311a57485394f313ee285dc8fa~mv2.png/v1/crop/x_46,y_0,w_989,h_1920/fill/w_392,h_758,al_c,usm_0.66_1.00_0.01,enc_auto/Find%20a%20specialist.png'}

            // image={screen3}
            // image={'https://static.wixstatic.com/media/0c398c_3e1a7d311a57485394f313ee285dc8fa~mv2.png/v1/crop/x_46,y_0,w_989,h_1920/fill/w_392,h_758,al_c,usm_0.66_1.00_0.01,enc_auto/Find%20a%20specialist.png'}
            />
            {/* <ProjectBox 
            title={'All Cape'}
            type={'Web Development'}
            link='https://all-cape-site.web.app/'
            key={'All Cape'}
            image='https://firebasestorage.googleapis.com/v0/b/all-cape-site.appspot.com/o/site%2Fimage.png?alt=media&token=ca64a076-6f5d-46a4-bbb4-228d5dddc809'
            // image={'https://static.wixstatic.com/media/0c398c_3e1a7d311a57485394f313ee285dc8fa~mv2.png/v1/crop/x_46,y_0,w_989,h_1920/fill/w_392,h_758,al_c,usm_0.66_1.00_0.01,enc_auto/Find%20a%20specialist.png'}
            /> */}
        </div>

    </div>

    </div>
  )
}

export default Home